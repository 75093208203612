export enum EventWidget {
  COMMENTS = 'COMMENTS',
  WISHLIST = 'WISHLIST',
  GALLERY = 'GALLERY',
  TICKET = 'TICKET',
  GAME_PLAN = 'GAME_PLAN',
  SELL_TICKETS = 'SELL_TICKETS',
  BOOKING_SLOTS = 'BOOKING_SLOTS',
}

export enum EventWidgetState {
  ACTIVE = 'ACTIVE',
  HIDDEN = 'HIDDEN',
  ALL = 'ALL',
}

export namespace EventWidget {
  export function getAllEventWidgets(): EventWidget[] {
    return [
      EventWidget.COMMENTS,
      EventWidget.WISHLIST,
      EventWidget.GALLERY,
      EventWidget.TICKET,
      EventWidget.GAME_PLAN,
      EventWidget.SELL_TICKETS,
      EventWidget.BOOKING_SLOTS,
    ];
  }

  export function getDisplayName(widget?: EventWidget): string {
    switch (widget) {
      case EventWidget.COMMENTS:
        return 'APP.EVENT_WIDGETS.COMMENTS';
      case EventWidget.WISHLIST:
        return 'APP.EVENT_WIDGETS.WISHLIST';
      case EventWidget.GALLERY:
        return 'APP.EVENT_WIDGETS.GALLERY';
      case EventWidget.TICKET:
        return 'APP.EVENT_WIDGETS.TICKET';
      case EventWidget.GAME_PLAN:
        return 'APP.EVENT_WIDGETS.GAME_PLAN';
      case EventWidget.SELL_TICKETS:
        return 'APP.EVENT_WIDGETS.SELL_TICKETS';
      case EventWidget.BOOKING_SLOTS:
        return 'APP.EVENT_WIDGETS.BOOKING_SLOTS';
      default:
        return '';
    }
  }

  export function getSellTicketsStepImage(step: number, lang: string): string {
    return lang === 'de'
      ? `https://static.eventpage.ai/apps/sell-tickets/de/sell-ticket_picture_step-${step}_de.png`
      : `https://static.eventpage.ai/apps/sell-tickets/en/sell-ticket_picture_step-${step}_en.png`;
  }
}
